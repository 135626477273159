<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-03 14:21:23
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-10 13:57:50
-->
<template>
  <div class="install-complete">
    <div class="personal-center-title">
      已完成
    </div>
    <div class="install-body">
      <div class="install-body-header">
        <div class="header-left">
          <span class="title">已完成订单</span>
          <span class="digital">{{ total }}条</span>
        </div>
        <div class="header-right">
          <el-input
            placeholder="搜索订单"
            v-model="search"
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="iconfont icon-sousu"
              @click="handleSearch"
            ></el-button>
          </el-input>
          <el-button
            icon="iconfont icon-shaixuan1"
            @click="isOpenSearch = !isOpenSearch"
          ></el-button>
        </div>
      </div>
      <div class="install-body-search" :class="{ open: isOpenSearch }">
        <!-- 320 -->
        <div class="search-item">
          <div class="label">订单号：</div>
          <el-input
            v-model="form.orderNum"
            placeholder="请输入订单号"
          ></el-input>
        </div>
        <div class="search-item">
          <div class="label">订单名称：</div>
          <el-input
            v-model="form.productName"
            placeholder="请输入订单名称"
          ></el-input>
        </div>
        <div class="search-item">
          <div class="label">订单类型：</div>
          <el-select
            :popper-append-to-body="false"
            v-model="form.type"
            placeholder="请选择"
          >
            <el-option label="请选择" value=""></el-option>
            <el-option label="安装单" value="1"></el-option>
            <el-option label="配件单" value="2"></el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="label">产品类型：</div>
          <el-select
            :popper-append-to-body="false"
            v-model="form.productType"
            placeholder="请选择"
          >
            <el-option label="请选择" value=""></el-option>
            <el-option
              v-for="item in goodTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="label">完成开始时间：</div>
          <el-date-picker
            :append-to-body="false"
            v-model="form.timeS"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div class="search-item">
          <div class="label">完成结束时间：</div>
          <el-date-picker
            :append-to-body="false"
            v-model="form.timeD"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div class="search-item search-item-btn">
          <div class="search-btn" @click="initInstallationOrder">搜索</div>
        </div>
      </div>
      <div class="install-body-list">
        <table>
          <thead>
            <tr class="list-header">
              <th class="header-item item-245">
                产品信息
              </th>
              <th class="header-item item-265">
                客户信息
              </th>
              <th class="header-item item-145">
                订单类型
              </th>
              <th class="header-item item-175">
                结算收益
              </th>
              <th class="header-item item-130">
                完成时间
              </th>
            </tr>
          </thead>
        </table>
        <table v-show="tableData.length > 0">
          <tbody
            class="list-line-item"
            v-for="(item, i) in tableData"
            :key="i"
            @click="handleDetails(item.id)"
          >
            <tr class="sep-row">
              <td colspan="5"></td>
            </tr>
            <tr>
              <td colspan="5" class="line-item-colspan">
                <span class="order-number"> 订单号：{{ item.orderNum }} </span>
                <span class="time">
                  安装时间：{{ item.serviceTime.split(' ')[0] }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="line-item item-245">
                <div class="img-or-name">
                  <img :src="$utils.picturePrefix(item.productImage)" />
                  <span>
                    {{ item.productName }}
                  </span>
                </div>
              </td>
              <td class="line-item item-265">
                <div class="real-name">
                  {{ item.clientName }}
                </div>
                <div class="address">
                  {{ item.clientAddress }}{{ item.clientAddressDetail }}
                </div>
                <div class="phone">
                  {{ item.clientPhone }}
                </div>
              </td>
              <td class="line-item item-145">
                <div class="order-type">
                  {{ $utils.orderTypeName(item.type) }}
                </div>
              </td>
              <td class="line-item item-175">
                <div class="order-money">
                  基础金额：￥{{ $utils.formatMoney(item.money) }}
                </div>
                <div class="order-money">
                  激励金额：￥{{ $utils.formatMoney(item.incentiveMoney) }}
                </div>
                <div class="order-money">
                  追加金额：￥{{ $utils.formatMoney(item.additionalMoney) }}
                </div>
              </td>
              <td class="line-item item-130">
                <div class="make-date">{{ item.completeTime }}</div>
              </td>
            </tr>
          </tbody>
        </table>
        <table v-show="tableData.length <= 0">
          <tbody class="list-line-item">
            <tr class="empty-row">
              <td colspan="4">
                暂无数据
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <u-pagination v-if="total > 0" :total="total" @change="changePagination"></u-pagination>
    </div>
  </div>
</template>

<script>
import UPagination from "@/components/UPagination";
import moment from "moment";

export default {
  components: {
    UPagination
  },
  data() {
    return {
      search: "",
      orderId: null,
      params: {
        num: 10,
        page: 1
      },
      total: 0,
      tableData: [],
      goodTypeList: [], // 产品类型
      isOpenSearch: false,
      form: {
        orderNum: "", // 订单号
        timeS: "", // 服务时间
        timeD: "", // 服务时间
        productName: "", // 产品名称
        productType: "", // 产品类型
        type: undefined // 订单类型
      }
    };
  },
  computed: {
    userId() {
      return this.$store.getters.userId;
    }
  },
  mounted() {
    this.getInstallationOrder();
    this.getInstallationOrderType();
  },
  methods: {
    // 规格 & 产品分类 下拉框
    getInstallationOrderType() {
      this.$api.getInstallationOrderType().then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.goodTypeList = data;
        }
      });
    },
    getInstallationOrder() {
      let params = {
        search: this.search,
        status: 5,
        userId: this.userId,
        ...this.params,
        ...this.form
      };
      params.type = params.type || "";
      params.timeS = params.timeS
        ? moment(params.timeS).format("YYYY-MM-DD")
        : ""; // 服务时间
      params.timeD = params.timeD
        ? moment(params.timeD).format("YYYY-MM-DD")
        : ""; // 服务时间
      const loading = this.$loading({
        lock: true,
        text: "查询中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .getInstallOrders(params)
        .then(res => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },
    initInstallationOrder() {
      this.params.page = 1;
      this.getInstallationOrder();
    },
    changePagination(page, pageSize) {
      this.params = {
        num: pageSize,
        page: page
      };
      this.getInstallationOrder();
    },
    handleSearch() {
      this.initInstallationOrder();
    },

    handleDetails(id) {
      this.$router.push({
        path: "/personal-center/install/details",
        query: { type: 4, oid: id }
      });
      sessionStorage.setItem('isReoder', 0)
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
